import { createApp, defineAsyncComponent } from "vue";

import SvgVue from 'svg-vue3'
import VueLazyLoad from 'vue3-lazyload'

const app = createApp({
  components: {
    Slider: defineAsyncComponent(() => import('./common/Slider.vue')),
    SocialIcons: defineAsyncComponent(() => import('./common/SocialMediaIcons.vue')),
  },

  data: () => ({
    openMenu: '',
    scrollPosition: 0,
  }),

  computed: {
    mobileMenu () {
      return this.openMenu === 'mobileMenu'
    },
    isScrolling () {
      return this.scrollPosition > 0
    },
  },

  methods: {
    toggleMenu (menu) {
      this.openMenu = (this.openMenu != menu ? menu : '')
    },
    handleScroll (event) {
      this.scrollPosition = window.pageYOffset != undefined ? window.pageYOffset : window.scrollY
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

})

app.use(VueLazyLoad)
app.use(SvgVue, {
  svgPath: 'resources/svg',
  extract: false,
  svgoSettings: [
      { removeTitle: true },
      { removeViewBox: false },
      { removeDimensions: true }
  ]
})

app.mount("#app")
